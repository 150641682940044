import { useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { getConfig } from 'config';
import { Context } from './Store';

import { useAuth0 } from '@auth0/auth0-react';
import types from './Reducer/types';

export default () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin = 'http://localhost:3001', audience } = getConfig();
  const api = useRef(
    axios.create({
      baseURL: `${apiOrigin}/api/`,
    }),
  );
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptorId = currentAPI.interceptors.request.use(async (config) => {
      const authParams = {
        authorizationParams: {
          audience,
          scope: 'read:users',
          redirect_uri: window.location.origin,
        },
        detailedResponse: true,
      };
      const token = await getAccessTokenSilently(authParams);
      const accessToken = token?.access_token || token;
      config.headers.authorization = `Bearer ${accessToken}`;
      config.cancelToken = axios.CancelToken.source().token;
      if (state.apiToken === accessToken) {
        return config;
      }
      try {
        dispatch({
          type: types.SET_API_TOKEN,
          payload: accessToken,
        });
        await axios.get(`${apiOrigin}/api/status`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        console.log(e);
      }
      return config;
    });
    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
    };
  });
  return api.current;
};
