import Axios from 'axios';

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const hasQRReaders = (location) => {
  return !!location['qr_readers'] && location['qr_readers'].length > 0;
}

const carouselsWithQRReaders = (location) => {
  if (!location['qr_readers']) {
    return [];
  }
  return [...new Set(location['qr_readers'].map((l) => l.carousel_id))];
}

const locationHasQRReader = (location) => {
  return location.hasQRReaders;
}

const getLocations = async () => {
  try {
    const response = await api.get('/locations');
    const locations = response.data;
    return locations.map((loc) => {
      return {
        id: loc.id,
        name: loc.name + ' - ' + loc.zipcode,
        latitude: loc.latitude,
        longitude: loc.longitude,
        availableSpaces: loc.available_spaces,
        totalSpaces: loc.total_spaces,
        is_park_with_geolocation: !!loc.is_park_with_geolocation,
        parking_radius_ft: loc.parking_radius_ft,
        is_geolocation_debug: !!loc.is_geolocation_debug,
        is_geolocation_high_accuracy: !!loc.is_geolocation_high_accuracy,
        geolocation_filter_length: loc.geolocation_filter_length,
        geolocation_filter_threshold_ft: loc.geolocation_filter_threshold_ft,
        is_geolocation_filter_replace_outliers_by_average: !!loc.is_geolocation_filter_replace_outliers_by_average,
        hasQRReaders: hasQRReaders(loc),
        carouselsWithQRReaders: carouselsWithQRReaders(loc),
      };
    });
  } catch (error) {
    console.log('Error getting locations list', error);
    throw error;
  }
};

const distanceInKm = (lat1, lon1, lat2, lon2) => {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

const distanceInFt = (lat1, lon1, lat2, lon2) => {
  return distanceInKm(lat1, lon1, lat2, lon2) * 3280.84;
}

const LocationService = {
  init,
  getLocations,
  locationHasQRReader,
  distanceInKm,
  distanceInFt,
};

export default LocationService;
