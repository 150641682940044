import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import LocationService from "../../services/LocationService"

const GeolocationContext = createContext();

export const useGeolocation = () => {
  return useContext(GeolocationContext);
};

const MINIMUM_FILTER_LEN = 2;

class DistanceFilter {
  constructor(location) {
    this.buffer = [];
    this.setConfig(location);
  }

  locationChanged(location) {
    return this.location == null ||
      this.location.geolocation_filter_length !== location.geolocation_filter_length ||
      this.location.geolocation_filter_threshold_ft !== location.geolocation_filter_threshold_ft ||
      this.location.is_geolocation_filter_replace_outliers_by_average !== !!location.is_geolocation_filter_replace_outliers_by_average;
  }

  setConfig(location) {
    if (this.locationChanged(location)) {
      this.N = location?.geolocation_filter_length ? Math.max(location.geolocation_filter_length, MINIMUM_FILTER_LEN) : MINIMUM_FILTER_LEN;
      this.threshold = location?.geolocation_filter_threshold_ft ? location.geolocation_filter_threshold_ft : 100;
      this.replaceOutliersByAverage = !!location?.is_geolocation_filter_replace_outliers_by_average;
      console.log(`${this.location ? 'Re-initialized' : 'Initialized'} DistanceFilter: N=${this.N}, threshold=${this.threshold} ft., replace outliers by average=${!!this.replaceOutliersByAverage}`);
    }
    this.location = location;
  }

  reinitialize(location) {
    const oldN = this.N;
    if (this.location.latitude !== location.latitude || this.location.longitude !== location.longitude) {
      this.buffer.length = 0;
    } else if (this.N < oldN) {
      this.buffer = this.buffer.slice(this.buffer.length - this.N);
    }
    this.setConfig(location);
  }

  addReading(reading) {
    let targetReading = reading;
    if (this.buffer.length >= this.N) {
      this.buffer.shift(); // Remove the oldest reading
    }
    this.buffer.push(reading);

    const average = this.buffer.reduce((acc, val) => acc + val, 0) / this.buffer.length;

    // Replace any outliers with latest reading (if outliers are mostly isolated) or average
    const isOutlier = Math.abs(reading - average) > this.threshold;
    if (isOutlier) {
      const reliableReading = this.replaceOutliersByAverage ? average : this.buffer[this.buffer.length - 2];
      this.buffer[this.buffer.length - 1] = reliableReading; // Replace the outlier
      targetReading = reliableReading;
    }
    return { reading: targetReading, isOutlier, bufferSize: this.buffer.length };
  }
}

export const usePosition = (location) => {
  const [position, setPosition] = useState({});
  const [distanceInFeet, setDistanceInFeet] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const [isDebugMode, setDebugMode] = useState(false);
  const [outlierCount, setOutlierCount] = useState(0);
  const [bufferSize, setBufferSize] = useState(0);
  const [positionCount, setPositionCount] = useState(0);
  const prevLocationRef = useRef(location);
  const distanceFilterRef = useRef(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
    setActive(!!coords.latitude && !!coords.longitude);
    setInitializing(false);
    setPositionCount((count) => count + 1);
  };

  const onError = (error) => {
    setError(error.message);
    setInitializing(false);
  };

  const feetFrom = (location) => {
    if (!active || !location) {
      return undefined;
    }
    return LocationService.distanceInFt(position.latitude, position.longitude, location.latitude, location.longitude);
  }

  const feetToDistanceString = (feet) => {
    if (!feet) {
      return null;
    }
    if (feet < 1000) {
      return `${feet.toFixed(0)} feet`
    }
    const miles = feet / 5280;
    if (miles < 100) {
      const milesStr = miles.toFixed(1);
      return `${milesStr} ${milesStr === '1.0' ? 'mile' : 'miles'}`
    }
    return `${miles.toFixed(0)} miles`
  }

  function didHighAccuracyChange(oldLocation, newLocation) {
    return !oldLocation || (oldLocation.is_geolocation_high_accuracy !== newLocation.is_geolocation_high_accuracy);
  }

  const geo = navigator.geolocation;
  const watcherRef = useRef(null);
  useEffect(() => {
    if (!location) {
      return;
    }

    const oldLocation = prevLocationRef.current;
    prevLocationRef.current = location;
    if (distanceFilterRef.current == null) {
      distanceFilterRef.current = new DistanceFilter(location);
    } else {
      distanceFilterRef.current.reinitialize(location);
    }

    if (!geo) {
      console.log('Geolocation not supported');
      setError('Geolocation is not supported');
      return;
    }

    const debugMode = !!location.is_geolocation_debug;
    if (debugMode) {
      console.log('Geolocation debug mode enabled');
    }
    setDebugMode(debugMode);
    if (watcherRef.current === null || didHighAccuracyChange(oldLocation, location)) {
      const highAccuracy = !!location.is_geolocation_high_accuracy;
      console.log(highAccuracy ? 'High accuracy geolocation enabled' : 'Geolocation enabled');
      watcherRef.current = geo.watchPosition(onChange, onError, {enableHighAccuracy: !!location.is_geolocation_high_accuracy});
    }
    return () => {
      if (didHighAccuracyChange(oldLocation, location)) {
        console.log('Clearing geolocation watcher');
        geo.clearWatch(watcherRef.current);
      }
    };
  }, [location]);

  useEffect(() => {
    if (position && 'latitude' in position && 'longitude' in position && prevLocationRef.current) {
      const distanceInFeet = feetFrom(prevLocationRef.current);
      if (distanceInFeet) {
        const { reading: filteredDistance, isOutlier, bufferSize } = distanceFilterRef.current.addReading(distanceInFeet);
        setDistanceInFeet(filteredDistance);
        if (isOutlier) {
          setOutlierCount((prev) => prev + 1);
        }
        setBufferSize(bufferSize);
      }
    }
  }, [position, prevLocationRef.current]);

  return { ...position, error, initializing, active, isDebugMode, positionCount, outlierCount, bufferSize, distanceInFeet, feetFrom, feetToDistanceString };
};

export const GeolocationProvider = ({ children, location }) => {
  const geolocation = usePosition(location);

  return (
    <GeolocationContext.Provider value={geolocation}>
      {children}
    </GeolocationContext.Provider>
  );
};

GeolocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};
