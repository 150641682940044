import defaultState from 'Store/defaultState';
import types from './types';

const Reducer = (state, action) => {
  if (state === null) {
    localStorage.removeItem('localState');
    return defaultState;
  }

  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case types.SET_PARKING_SESSION:
      return {
        ...state,
        parkingSession: action.payload || null,
      };
    case types.SET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notificationCount: action.payload,
      };
    case types.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case types.SET_TNC_CHANGE:
      return {
        ...state,
        tncChange: action.payload,
      };
    case types.SET_API_TOKEN:
      return {
        ...state,
        apiToken: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
