import { useEffect, useRef } from "react";

export function convertNullsToEmptyStrings(obj) {
  Object.keys(obj).forEach((key) => {
    obj[key] = obj[key] ? obj[key] : '';
  });
}

export function convertApiError(error) {
  console.log('API error', error);
  let errMsg = error?.response?.data?.error;
  if (errMsg && typeof errMsg === 'string') {
    if (errMsg.startsWith('Error: ')) {
      errMsg = errMsg.substring(7);
    }
    return errMsg;
  } else {
    errMsg = error?.response?.data?.message;
    if (errMsg && typeof errMsg === 'string') {
      if (errMsg.startsWith('Error: ')) {
        errMsg = errMsg.substring(7);
      }
      return errMsg;
    }
  }
  return error;
}

export const useRunOnce = ({ fn, sessionKey }) => {
  const triggered = useRef(false);

  useEffect(() => {
    const hasBeenTriggered = sessionKey
      ? sessionStorage.getItem(sessionKey)
      : triggered.current;

    if (!hasBeenTriggered) {
      fn();
      triggered.current = true;

      if (sessionKey) {
        sessionStorage.setItem(sessionKey, "true");
      }
    }
  }, [fn, sessionKey]);

  return null;
};

