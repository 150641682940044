function processConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.

  const audience =
    process.env.REACT_APP_AUTH0_AUDIENCE && process.env.REACT_APP_AUTH0_AUDIENCE !== 'YOUR_API_IDENTIFIER'
      ? process.env.REACT_APP_AUTH0_AUDIENCE
      : null;

  function keepProps(obj, keep) {
    for (var prop in obj) {
      if (keep.indexOf( prop ) === -1) {
        delete obj[prop];
      }
    }
  }

  const vehicleTypes = {
    SEDAN: {
      maxLength: '17 FT. 1 IN.',
      maxWidth: '6 FT. 10 IN.',
      maxHeight: '5 FT. 3 IN.',
      maxWeight: '4,800 LB.',
    },
    SUV: {
      maxLength: '17 FT. 1 IN.',
      maxWidth: '6 FT. 10 IN.',
      maxHeight: '6 FT. 2 IN.',
      maxWeight: '5,300 LB.',
    },
    MOTORCYCLE: {},
    BICYCLE: {},
  };
  const allowedVehicleTypes = (process.env.REACT_APP_ALLOWED_VEHICLE_TYPES || 'SEDAN,SUV').split(',');
  keepProps(vehicleTypes, allowedVehicleTypes);

  return {
    testMode: process.env.REACT_APP_TEST_MODE && (process.env.REACT_APP_TEST_MODE.toLowerCase() === 'true'), // not used since park test button removed
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    apiOrigin: process.env.REACT_APP_API_ORIGIN,
    stripePublishKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
    authorizeNetHost: process.env.REACT_APP_AUTHORIZE_HOST,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    supportNumber: process.env.REACT_APP_SUPPORT_NUMBER,
    vehicleTypes,
    audience,
  };
}
const theConfig = processConfig();
console.info('config', JSON.stringify(theConfig));

export function getConfig() {
   return theConfig;
 }
